<template>
  <div class="find_number">
    <div class="head">找回账号</div>
    <div class="cancel">
      <i @click="goback" class="el-icon-close clbtn"></i>
    </div>
    <div class="user_info" v-if="step">
      <el-form :inline="true" :model="companyInfo" class="demo-form-inline" ref="form1">
        <el-form-item
          :rules="[
            { required: true, message: '请输入公司名称', trigger: 'change' }
          ]"
          label="公司名称"
          prop="name"
        >
          <el-input placeholder="公司名称" v-model="companyInfo.name"></el-input>
        </el-form-item>
        <el-form-item
          :rules="[
            { required: true, message: '请输入公司税号', trigger: 'blur' }
          ]"
          label="公司税号"
          prop="creditCode"
        >
          <el-input
            @input="checkCode(companyInfo.creditCode)"
            placeholder="公司税号"
            v-model="companyInfo.creditCode"
          ></el-input>
        </el-form-item>
        <el-form-item class="button">
          <el-button @click="next" type="primary">下一步</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="user_change" v-else>
      <el-form :inline="true" :model="userInfo" class="demo-form-inline" ref="form2">
        <el-form-item
          :rules="[{ required: true, message: '请输入账号', trigger: 'blur' },
            { pattern: /^1[34578]\d{9}$/, message: '电话号输入有误' }
          ]"
          label="修改账号"
          prop="loginPhone"
        >
          <el-input
            oninput="if(value.length>5)value=value.slice(0,11)"
            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
            placeholder="修改账号"
            v-model="userInfo.loginPhone"
          ></el-input>
        </el-form-item>
        <el-form-item
          :rules="[{ required: true, message: '请输入密码', trigger: 'blur' }]"
          label="修改密码"
          prop="password"
        >
          <el-input placeholder="修改密码" v-model="userInfo.password"></el-input>
        </el-form-item>
        <el-form-item class="button">
          <el-button @click="submit" type="primary">确认</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "findNumber",
  data () {
    return {
      msg: "",
      step: true,
      companyInfo: {
        name: "",
        creditCode: ""
      },
      userInfo: {
        id: "",
        loginPhone: "",
        password: "",
      }
    };
  },
  cerated () { },
  mounted () { },
  methods: {
    checkCode (item) {
      this.companyInfo.creditCode = item.replace(/(\w{4})(?=\w)/g, '$1 ').replace(/(\d{4})(?=\d)/g, '$1 ').toUpperCase();
    },
    goback () {
      this.$router.replace("/");
    },
    next () {
      // this.step = false
      console.log('jjss', this.companyInfo);

      var url = this.$api.checkUserInfo;
      this.$refs.form1.validate(valid => {
        if (valid && this.companyInfo !== "")
          debugger
        this.$request.post(url, this.companyInfo).then(res => {
          if (res.status == 200 && res.data != "") {
            this.step = false;
            console.log('reeeee', res);
            this.userInfo.id = res.data;
          } else {
            this.$message.error(
              "请输入正确的公司名称/税号，如忘记请联系管理人员!"
            );
          }
        });
      });
    },
    submit () {
      console.log('this.user', this.userInfo);
      this.$refs.form2.validate(valid => {
        if (valid && this.userInfo !== "") {

          let url = this.$api.ifNumber + this.userInfo.loginPhone;

          this.$request.get(url).then(res => {
            if (res.status == 200) {
              console.log('ppp');
              this.$request.put(this.$api.amendNumber, this.userInfo).then(res => {
                // 17701246400
                if (res.status == 200) {
                  this.$message.success("修改成功，请重新登录！");
                  this.$router.push("/login");
                }
              });

            } else if (res.status == 201) {
              this.$message({
                message: '该手机号已注册,请重新输入!',
                type: 'warning'
              });
            }
          })




          // this.$request.put(this.$api.amendNumber, this.userInfo).then(res => {
          //   // 17701246400
          //   if (res.status == 200) {
          //     this.$message.success("修改成功，请重新登录！");
          //     this.$router.push("/login");
          //   }
          // });
        }
      });


    }
  }
};
</script>

<style lang="less" scoped>
.find_number {
  z-index: 999;
  border-radius: 5px;
  height: 350px;
  width: 320px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
  border: 1px solid #dcdfe6;
  padding: 0 15px;
  .cancel {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
  }
  .head {
    font-size: 25px;
    text-align: center;
    margin: 20px 0;
  }

  .user_info,
  .user_change {
    margin-top: 35px;
  }

  /deep/.el-input__inner {
    height: 35px;
  }
  /deep/.el-form--inline .el-form-item {
    margin-left: 26.5px;
    margin-bottom: 35px;
  }
  /deep/.button {
    width: 100%;
    margin-top: 30px;
    margin-left: 0 !important;
    margin-right: 0 !important;
    text-align: center;
    /deep/.el-form--inline .el-form-item__content {
      width: 120px;
    }
  }
  /deep/.button .el-form--inline .el-form-item__content {
    width: 120px;
  }
  /deep/.el-button {
    width: 120px;
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
    //  margin-left: 40px;
  }
}
</style>
